<template>
  <div class="warehouse-detail">
    <Breadcrumb :items="breadCrumbs" />
    <div class="grey lighten-3 py-6">
      <v-container>
        <v-card elevation="3" :key="warehouse.warehouseId">
          <v-row>
            <v-col cols="12" sm="4" xl="3">
              <div class="pa-6 warehouse-detail-column">
                <h1>
                  <img src="/pdv/pin.png" alt="pin negozio" />{{
                    warehouse.name
                  }}
                </h1>
                <div class="mb-4" v-if="warehouse.address">
                  <strong
                    >{{ warehouse.address.address1 }}
                    {{ warehouse.address.addressNumber }}<br />
                    {{ warehouse.address.city }} ({{
                      warehouse.address.province
                    }}) {{ warehouse.address.postalcode }}</strong
                  >
                </div>
                <!-- <div v-if="warehouseInfo">
                  <strong>Email: </strong>
                  <a :href="'mailto:' + warehouseInfo.EMAIL">{{
                    warehouseInfo.EMAIL
                  }}</a>
                </div> -->
                <!-- <div v-if="warehouseInfo">
                  <strong>Email delivery: </strong>
                  <a :href="'mailto:' + warehouseInfo.EMAIL_DELIVERY">{{
                    warehouseInfo.EMAIL_DELIVERY
                  }}</a>
                </div> -->
                <div v-if="warehouseInfo">
                  <strong>Telefono: </strong>
                  <a :href="'tel:' + warehouseInfo.PHONE">{{
                    warehouseInfo.PHONE
                  }}</a>
                </div>

                <div
                  v-if="warehouseInfo"
                  v-html="warehouseInfo.SERVICES_DESCR"
                ></div>
                <div
                  class="mt-3"
                  v-if="warehouseInfo"
                  v-html="warehouseInfo.OPENING_TIMES"
                ></div>
                <div class="mt-3 alternative-payment-method">
                  <img src="/pdv/card.svg" alt="card" />
                  <router-link
                    :to="{
                      name: 'Page',
                      params: { pageName: 'servizi' }
                    }"
                  >
                    {{ $t("storeLocator.mealTicket") }}
                  </router-link>
                </div>
                <div class="mt-2 alternative-payment-method">
                  <img src="/pdv/gift-card.svg" alt="gift card" />
                  <router-link
                    :to="{
                      name: 'Page',
                      params: { pageName: 'servizi' }
                    }"
                  >
                    {{ $t("storeLocator.giftCard") }}
                  </router-link>
                </div>
              </div>
            </v-col>
            <v-col cols="12" sm="8" xl="9">
              <GmapMap
                v-if="warehouse.warehouseId"
                ref="mapRef"
                :center="{
                  lat: warehouse.address.latitude,
                  lng: warehouse.address.longitude
                }"
                :zoom="15"
                :style="{ width: mapWidth, height: mapHeight }"
                :options="{
                  zoomControl: true,
                  mapTypeControl: false,
                  scaleControl: false,
                  streetViewControl: false,
                  rotateControl: false,
                  fullscreenControl: false,
                  disableDefaultUI: false
                }"
              >
              </GmapMap>
            </v-col>
          </v-row>
        </v-card>
      </v-container>
    </div>
    <div class="">
      <v-container>
        <v-row>
          <v-col cols="12" sm="6"> <v-img src="/img/contact-store.jpg"/></v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>
<style lang="scss">
.warehouse-detail {
  .warehouse-list-list {
    height: 380px;
    overflow-y: auto;
  }
  .warehouse-detail-column {
    h1 {
      font-weight: 600;
      font-size: 22px;
      line-height: 150%;
      /* identical to box height, or 33px */
      letter-spacing: -0.02em;
      text-transform: uppercase;

      color: var(--v-secondary-base);
    }
    .alternative-payment-method {
      display: flex;
      align-items: center;
      font-weight: bold;
      font-size: 14px;
      img {
        margin-right: 5px;
        width: 35px;
      }
    }
    a {
      text-decoration: none;
    }
    font-size: 16px;
    line-height: 150%;
    /* or 24px */

    color: $text-color;
    a {
      color: var(--v-secondary-base);
    }
  }
}
</style>
<script>
import AddressService from "~/service/addressService";
import Breadcrumb from "@/components/navigation/Breadcrumb";
// import FormServizioClienti from "@/components/wordpress/FormServizioClienti.vue";
import { gmapApi } from "vue2-google-maps";

export default {
  name: "WarehouseDetail",
  components: { Breadcrumb },
  data() {
    return {
      warehouseList: [],
      warehouse: {}
    };
  },
  computed: {
    google: gmapApi,
    mapWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "351px";
        case "sm":
          return "573px";
        case "md":
          return "563px";
        case "lg":
          return "754px";
        case "xl":
          return "1061px";
      }
      return "850px";
    },
    mapHeight() {
      return "480px";
    },
    breadCrumbs() {
      let breadCrumbs = [
        { to: { name: "Home" }, text: "Homepage", exact: true },
        { to: { name: "WarehouseList" }, text: "Punti Vendita", exact: true }
      ];
      if (this.warehouse) {
        breadCrumbs.push({
          to: {
            name: "WarehouseDetail",
            params: {
              warehouseId: this.warehouse.warehouseId
            }
          },
          text: this.warehouse.name,
          exact: true
        });
      }
      return breadCrumbs;
    },
    warehouseInfo() {
      return this.warehouse.metaData && this.warehouse.metaData.warehouse_info;
    }
  },
  methods: {
    async findWarehouse() {
      if (this.warehouseList.length == 0) {
        let data = await AddressService.findWarehouse({
          region_id: this.selectedRegionId
        });
        this.warehouseList = data.warehouses;
      }
      this.warehouse = this.warehouseList.find(
        element => element.warehouseId == this.$route.params.warehouseId
      );
    }
  },
  mounted() {
    this.findWarehouse();
  },
  watch: {
    $route() {
      this.findWarehouse();
    }
  }
};
</script>
