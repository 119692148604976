var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"warehouse-detail"},[_c('Breadcrumb',{attrs:{"items":_vm.breadCrumbs}}),_c('div',{staticClass:"grey lighten-3 py-6"},[_c('v-container',[_c('v-card',{key:_vm.warehouse.warehouseId,attrs:{"elevation":"3"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4","xl":"3"}},[_c('div',{staticClass:"pa-6 warehouse-detail-column"},[_c('h1',[_c('img',{attrs:{"src":"/pdv/pin.png","alt":"pin negozio"}}),_vm._v(_vm._s(_vm.warehouse.name)+" ")]),(_vm.warehouse.address)?_c('div',{staticClass:"mb-4"},[_c('strong',[_vm._v(_vm._s(_vm.warehouse.address.address1)+" "+_vm._s(_vm.warehouse.address.addressNumber)),_c('br'),_vm._v(" "+_vm._s(_vm.warehouse.address.city)+" ("+_vm._s(_vm.warehouse.address.province)+") "+_vm._s(_vm.warehouse.address.postalcode))])]):_vm._e(),(_vm.warehouseInfo)?_c('div',[_c('strong',[_vm._v("Telefono: ")]),_c('a',{attrs:{"href":'tel:' + _vm.warehouseInfo.PHONE}},[_vm._v(_vm._s(_vm.warehouseInfo.PHONE))])]):_vm._e(),(_vm.warehouseInfo)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.warehouseInfo.SERVICES_DESCR)}}):_vm._e(),(_vm.warehouseInfo)?_c('div',{staticClass:"mt-3",domProps:{"innerHTML":_vm._s(_vm.warehouseInfo.OPENING_TIMES)}}):_vm._e(),_c('div',{staticClass:"mt-3 alternative-payment-method"},[_c('img',{attrs:{"src":"/pdv/card.svg","alt":"card"}}),_c('router-link',{attrs:{"to":{
                    name: 'Page',
                    params: { pageName: 'servizi' }
                  }}},[_vm._v(" "+_vm._s(_vm.$t("storeLocator.mealTicket"))+" ")])],1),_c('div',{staticClass:"mt-2 alternative-payment-method"},[_c('img',{attrs:{"src":"/pdv/gift-card.svg","alt":"gift card"}}),_c('router-link',{attrs:{"to":{
                    name: 'Page',
                    params: { pageName: 'servizi' }
                  }}},[_vm._v(" "+_vm._s(_vm.$t("storeLocator.giftCard"))+" ")])],1)])]),_c('v-col',{attrs:{"cols":"12","sm":"8","xl":"9"}},[(_vm.warehouse.warehouseId)?_c('GmapMap',{ref:"mapRef",style:({ width: _vm.mapWidth, height: _vm.mapHeight }),attrs:{"center":{
                lat: _vm.warehouse.address.latitude,
                lng: _vm.warehouse.address.longitude
              },"zoom":15,"options":{
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
                disableDefaultUI: false
              }}}):_vm._e()],1)],1)],1)],1)],1),_c('div',{},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-img',{attrs:{"src":"/img/contact-store.jpg"}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }